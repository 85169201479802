import styled from 'styled-components'

import { breakpointsRaw, breakpoints } from '../ui-kit/globalValue'

export const Container = styled.div`
    padding: 0 15px;
    @media (min-width: ${breakpoints.tablet}) {
        width: ${breakpointsRaw.tablet - 30}px;
        margin: 0 auto;
    }

    @media (min-width: ${breakpoints.desktop}) {
        width: ${breakpointsRaw.desktop - 30}px;
        margin: 0 auto;
    }
`
