import styled from 'styled-components'

const TitelSizes = {
    h1: 'font-size:38px;',
    h2: 'font-size:32px;',
    h3: 'font-size:24px;',
    h4: 'font-size:20px;',
    h5: 'font-size:16px;',
    h6: 'font-size:12px;',
}
const Color = {
    white: 'color:#fff',
    black: 'color:#000',
}
const View = {
    underlined: `   position: relative;
    width: auto;
    :after {
        position: absolute;
        bottom: -6px;
        left: 0;
        right:0;
        margin: auto;
        max-width: 20%;
        width: 120px;
        border-top: 2px solid;
        content: '';
    }
`,
}

const Align = {
    left: `
        text-align: left;
        :after {
            margin: 0;
            width: 60px;
        }
        `,
    center: `
        text-align: center;
        `,
    right: `
        text-align: right;
        :after {
            margin: 0
            width: 60px;
            left:auto;
        }`,
}

export const Title = styled.h1`
    ${(props) => TitelSizes[props.as]};
    ${(props) => View[props.view]};
    ${(props) => Color[props.color]};
    margin: 1.5em 0;
    font-family: 'Merriweather', serif;
    text-transform: uppercase;
    text-align: center;
    ${(props) => Align[props.alignment]};
`
