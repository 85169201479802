import React from 'react'
import styled from 'styled-components'

import { breakpoints, headerHeights } from '../../ui-kit/globalValue'
import { Container } from 'components/ui-kit/Container'

export const HeaderTag = styled.header`
    position: fixed;
    z-index: 1;
    width: 100%;
    background-color: #333;
    color: #fff;
    flex: 1 0 auto;
`
const HeaderContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: ${headerHeights.mobile};

    @media (min-width: ${breakpoints.tablet}) {
        height: ${headerHeights.tablet};
        box-sizing: border-box;
        padding: 5px 0;
    }

    @media (min-width: ${breakpoints.desktop}) {
        height: ${headerHeights.desktop};
    }
`

export const Header = ({ children, Component = HeaderTag }) => (
    <Component>
        <HeaderContainer>{children}</HeaderContainer>
    </Component>
)
