import React from 'react'
import { Link } from 'gatsby-plugin-modal-routing'

import { Button } from 'components/ui-kit/Button'
import { useTranslations } from 'i18n/useTranslations'
import { useLocalizedLink } from 'i18n/Link'

export const RequestPrice = () => {
    const translations = useTranslations()
    const localizedLink = useLocalizedLink('/request-price/')

    return (
        <Button as={Link} view="dark" to={localizedLink} asModal>
            {translations.requestPrice}
        </Button>
    )
}
