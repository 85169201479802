import React from 'react'
import { Location } from '@reach/router'

export const withLocation = (WrappedComponent) => {
    const WithLocation = (props) => {
        return (
            <Location>
                {({ location }) => (
                    <WrappedComponent {...props} location={location.pathname} />
                )}
            </Location>
        )
    }
    return WithLocation
}
