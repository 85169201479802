import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import { MobileHeader } from '../common/header/MobileHeader'
import { DesktopHeader } from '../common/header/DesktopHeader'
import { Footer } from 'components/common/Footer'
import { breakpoints, headerHeights } from '../ui-kit/globalValue'
import { useLocaleConfig } from 'i18n/localeConfigs'
import { AlternateLinks } from 'i18n/AlternateLinks'

const Main = styled.div`
    flex: 1 0 auto;
    padding-top: ${headerHeights.mobile};
    overflow: none;
    @media (min-width: ${breakpoints.tablet}) {
        padding-top: ${headerHeights.tablet};
    }
    @media (min-width: ${breakpoints.desktop}) {
        padding-top: ${headerHeights.desktop};
    }
`

export const Page = (props) => {
    const localeConfig = useLocaleConfig()

    return (
        <>
            {/* Global MetaData */}
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content={process.env.GATSBY_META_ROBOTS} />

                <html lang={localeConfig.languageTag} />
            </Helmet>

            <AlternateLinks />

            <MobileHeader />
            <DesktopHeader />
            <Main>{props.children}</Main>
            <Footer />
        </>
    )
}
