import React from 'react'
import styled from 'styled-components'

import Phone from 'assets/icons/phone.svg'
import { Container } from 'components/ui-kit/Container'
import { LogoTag, Logo } from 'components/common/header/components/Logo'
import { breakpoints } from 'components/ui-kit/globalValue'
import { useTranslations } from 'i18n/useTranslations'
import { contactInformation } from 'data/contactInformation'

const FooterTag = styled.footer`
    flex-shrink: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
`
const FooterContainer = styled(Container)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 20px 15px;
    @media (min-width: ${breakpoints.tablet}) {
        grid-template-rows: 1fr 1fr;
    }
    @media (min-width: ${breakpoints.desktop}) {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
`
const Contact = styled.div`
    grid-area: 2/1/3/3;
    padding: 5px 0;
    @media (min-width: ${breakpoints.tablet}) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        grid-area: 1/2/3/3;
        padding: 0;
    }
`

const ContactTitle = styled.h3`
    text-transform: uppercase;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 0.4em;
    @media (min-width: ${breakpoints.desktop}) {
        font-family: 'Merriweather', serif;
        font-size: 28px;
        line-height: 30px;
    }
`
const ContactLinks = styled.a`
    display: block;

    font-size: 20px;
    line-height: 30px;
    color: #fff;
    text-decoration: none;
    :hover {
        opacity: 0.7;
    }
`
const PhoneIconStyled = styled(Phone)`
    height: 16px;
    fill: white;
    transform: rotate(90deg);
    vertical-align: baseline;
    @media (min-width: ${breakpoints.desktop}) {
        height: 20px;
        line-height: 23px;
    }
`
const FooterLogoTag = styled(LogoTag)`
    grid-area: 1/1/2/2;
    align-self: start;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 0.4em;
    @media (min-width: ${breakpoints.tablet}) {
        margin-bottom: 0;
        width: 100px;
    }
    @media (min-width: ${breakpoints.desktop}) {
        font-size: 40px;
        line-height: 40px;
        width: 140px;
    }
`
const Copyright = styled.p`
    grid-area: 4/1/5/2;
    margin-top: 0.4em;
    font-size: 19px;
    line-height: 25px;
    @media (min-width: ${breakpoints.tablet}) {
        margin-top: 0;
        font-size: 18px;
        grid-area: 2/1/3/2;
        align-self: end;
    }
    @media (min-width: ${breakpoints.desktop}) {
        font-size: 24px;
    }
`

export const Footer = () => {
    const translations = useTranslations()
    return (
        <FooterTag>
            <FooterContainer>
                <Logo Component={FooterLogoTag} />
                <Contact>
                    <ContactTitle>{translations.contacts}</ContactTitle>

                    <ContactLinks
                        href={`tel:${contactInformation.phoneNumberFooterTop}`}
                    >
                        <PhoneIconStyled />{' '}
                        {contactInformation.phoneNumberFooterTop}
                    </ContactLinks>
                    <ContactLinks
                        href={`tel:${
                            contactInformation.phoneNumberFooyerBottom
                        }`}
                    >
                        <PhoneIconStyled />{' '}
                        {contactInformation.phoneNumberFooyerBottom}
                    </ContactLinks>
                    <ContactLinks href={`mailto:${contactInformation.mail}`}>
                        {contactInformation.mail}
                    </ContactLinks>
                </Contact>
                <Copyright>{translations.copyright}</Copyright>
            </FooterContainer>
        </FooterTag>
    )
}
