import React from 'react'
import { Helmet } from 'react-helmet'
import { omit } from 'lodash'

import { withLocation } from 'utils/withLocation'
import { localeConfigs } from 'i18n/localeConfigs'
import { useLocale } from 'i18n/useLocale'
import { getDefaultLocaleLink, getLocalizedLink } from 'i18n/Link'

/**
 * AlternateLinks
 *
 * Renders alternate links for other language version of the current page
 */
export const AlternateLinks = withLocation(({ location }) => {
    const currentLocale = useLocale()
    const defaultLocaleLink = getDefaultLocaleLink(location, currentLocale)

    const otherLocales = omit(localeConfigs, currentLocale)

    return (
        <Helmet>
            {Object.values(otherLocales).map(({ locale, languageTag }) => (
                <link
                    key={locale}
                    rel="alternate"
                    hreflang={languageTag}
                    href={getLocalizedLink(defaultLocaleLink, locale)}
                />
            ))}
        </Helmet>
    )
})
