import React from 'react'
import styled from 'styled-components'

import { Link } from 'i18n/Link'
import { breakpoints } from '../../../ui-kit/globalValue'
import { useTranslations } from 'i18n/useTranslations'
import { useLocalizedLink } from 'i18n/Link'
import { withLocation } from 'utils/withLocation'

const MenuTag = styled.ul`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 60%;
    font-size: 24px;
    line-height: 35px;

    & li {
        text-align: center;
        width: 100%;
        & a {
            display: block;
            padding: 10px 0;
            :hover {
                color: #999;
            }
        }
    }

    @media (min-width: ${breakpoints.tablet}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 30px;
        height: 35px;
        font-size: 18px;
        & li {
            width: auto;
        }
    }

    @media (min-width: ${breakpoints.desktop}) {
        flex-direction: row;
        justify-content: space-between;
        padding-right: 60px;
        height: 45px;
        font-size: 22px;
    }
`

const StyledLink = styled(Link)`
    width: 100%;
    text-decoration: none;
    color: #fff;
`
const CurrentPage = styled.span`
    position: relative;
    width: 100%;
    :after {
        position: absolute;
        bottom: -3px;
        left: 15%;
        width: 70%;
        border-top: 2px solid #fff;
        content: '';
    }
`

const MenuItem = ({ pathName, label, currentPathName, onClick }) => {
    const localizedPathName = useLocalizedLink(pathName)
    return (
        <li>
            {currentPathName === localizedPathName ? (
                <CurrentPage onClick={onClick}>{label}</CurrentPage>
            ) : (
                <StyledLink to={pathName}>{label}</StyledLink>
            )}
        </li>
    )
}

const getMenuItems = (translations) => [
    {
        pathName: '/about/',
        label: translations.about,
    },
    {
        pathName: '/product/',
        label: translations.products,
    },
    {
        pathName: '/logistic/',
        label: translations.logistics,
    },
    {
        pathName: '/reporting/',
        label: translations.reporting,
    },
    {
        pathName: '/contacts/',
        label: translations.contacts,
    },
]

export const HeaderMenuRaw = ({
    location,
    onActiveElementClick = () => null,
}) => {
    const translations = useTranslations()

    return (
        <MenuTag>
            {getMenuItems(translations).map((menuItem) => (
                <MenuItem
                    key={menuItem.pathName}
                    pathName={menuItem.pathName}
                    label={menuItem.label}
                    currentPathName={location}
                    onClick={onActiveElementClick}
                />
            ))}
        </MenuTag>
    )
}

export const HeaderMenu = withLocation(HeaderMenuRaw)
