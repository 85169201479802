import React, { useState } from 'react'
import styled from 'styled-components'

import { Logo } from './components/Logo'
import { HeaderPhone } from './components/HeaderPhone'
import { RequestPrice } from './components/RequestPrice'
import { HeaderMenu } from './components/HeaderMenu'
import { LanguageSwitch } from './components/LanguageSwitch'
import { Header } from './Header'
import { breakpoints } from '../../ui-kit/globalValue'
import { HeaderTag } from './Header'

const MobileHeaderTag = styled(HeaderTag)`
    @media (min-width: ${breakpoints.tablet}) {
        display: none;
    }
`
const Burger = styled.button`
    width: 35px;
    height: 35px;
    background-color: #333;
    border: solid #fff;

    position: relative;
`
const BurgerLine = styled.span`
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    height: 2px;
    background-color: #fff;
`
const BurgerLineTop = styled(BurgerLine)`
    position: absolute;
    top: 50%;
    margin-top: -9px;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    height: 2px;
    background-color: #fff;
`
const BurgerLineTopHidden = styled(BurgerLineTop)`
    transform: rotate(45deg);
    transition: 5s;
    margin-top: -1px;
    width: 28px;
    margin-left: -14px;
`
const BurgerLineBottom = styled(BurgerLine)`
    position: absolute;
    top: 50%;
    margin-top: 7px;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    height: 2px;
    background-color: #fff;
`
const BurgerLineBottomHidden = styled(BurgerLineBottom)`
    transform: rotate(-45deg);
    margin-top: -1px;
    width: 28px;
    margin-left: -14px;
    transition: 5s;
`

const MobileHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
`
const MobileHeaderHidden = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    width: 100vw;
    background-color: #333;
`

export const MobileHeader = () => {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <Header Component={MobileHeaderTag}>
            <MobileHeaderTop>
                <Logo />
                <RequestPrice />
                <Burger
                    aria-label="Меню"
                    onClick={() => setIsVisible(!isVisible)}
                >
                    {!isVisible && <BurgerLineTop />}
                    {isVisible && <BurgerLineTopHidden />}
                    {!isVisible && <BurgerLine />}
                    {isVisible && <BurgerLineBottomHidden />}
                    {!isVisible && <BurgerLineBottom />}
                </Burger>
            </MobileHeaderTop>
            {isVisible && (
                <MobileHeaderHidden>
                    <LanguageSwitch />
                    <HeaderMenu
                        onActiveElementClick={() => setIsVisible(false)}
                    />
                    <HeaderPhone />
                </MobileHeaderHidden>
            )}
        </Header>
    )
}
