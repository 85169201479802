import React from 'react'
import styled from 'styled-components'

import { Link } from 'i18n/Link'
import { breakpoints } from '../../../ui-kit/globalValue'
import { useTranslations } from 'i18n/useTranslations'

export const LogoTag = styled(Link)`
    font-size: 26px;
    font-family: 'Merriweather', serif;
    font-weight: bold;
    text-decoration: none;
    color: #ffffff;
    line-height: 35px;
    @media (min-width: ${breakpoints.desktop}) {
        font-size: 36px;
        line-height: 45px;
    }
    :hover {
        opacity: 0.7;
    }
`
export const Logo = () => {
    const translations = useTranslations()
    return <LogoTag to="/">{translations.logo}</LogoTag>
}
