import styled from 'styled-components'

const View = {
    light: `
        border-color: #222;
        color: #222;
        :hover {
            color: #fff;
            background-color: #222;
        }
        :focus {
            outline: none;
            border-color: #555;
        }
    `,
    dark: `
        border-color: #fff;
        color: #fff;
        :hover {
            color: #222;
            background-color: #fff;
        }
        :focus {
            outline: none;
            border-color: #bbb;
        }
    `,
}

export const Button = styled.button`
    padding: 5px 20px;
    background-color: transparent;
    font-size: 18px;
    cursor: pointer;
    transition-property: color, background-color;
    transition-duration: 0.35s;
    white-space: nowrap;
    text-decoration: none;
    border-width: 2px;
    border-style: solid;
    ${(props) => View[props.view]};
`
