import React from 'react'
import styled from 'styled-components'

import { Logo } from './components/Logo'
import { HeaderPhone } from './components/HeaderPhone'
import { RequestPrice } from './components/RequestPrice'
import { HeaderMenu } from './components/HeaderMenu'
import { LanguageSwitch } from './components/LanguageSwitch'
import { breakpoints } from '../../ui-kit/globalValue'
import { Header } from './Header'
import { HeaderTag } from './Header'

const DesktopHeaderTag = styled(HeaderTag)`
    display: none;
    @media (min-width: ${breakpoints.tablet}) {
        display: flex;
    }
`

const TopHeader = styled.div`
    display: flex;
    justify-content: space-between;
`
const TopHeaderRight = styled.div`
    display: flex;
    flex-wrap: none;
    justify-content: space-between;
`

const BottomHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const DesktopHeader = () => (
    <Header Component={DesktopHeaderTag}>
        <TopHeader>
            <Logo />
            <TopHeaderRight>
                <HeaderPhone />
                <LanguageSwitch />
            </TopHeaderRight>
        </TopHeader>
        <BottomHeader>
            <HeaderMenu />
            <RequestPrice />
        </BottomHeader>
    </Header>
)
