import React from 'react'
import styled from 'styled-components'
import { breakpoints } from 'components/ui-kit/globalValue'
import Phone from 'assets/icons/phone.svg'
import { contactInformation } from 'data/contactInformation'

const PhoneTag = styled.a`
    display: inline-flex;
    text-decoration: none;
    font-size: 24px;
    color: #ffffff;
    white-space: nowrap;
    @media (min-width: ${breakpoints.tablet}) {
        font-size: 18px;
    }
    @media (min-width: ${breakpoints.desktop}) {
        align-items: center;
        font-size: 22px;
    }
    :hover {
        opacity: 0.7;
    }
`
const PhoneStyled = styled(Phone)`
    height: 24px;
    fill: white;
    padding-top: 5px;
    transform: rotate(90deg);
    @media (min-width: ${breakpoints.tablet}) {
        height: 18px;
    }
    @media (min-width: ${breakpoints.desktop}) {
        height: 22px;
    }
`
const PhoneBox = styled.div`
    display: flex;
    justify-content: center;
    height: 35px;
    padding-bottom: 10px;
    @media (min-width: ${breakpoints.tablet}) {
        padding-bottom: 0;
        align-items: center;
    }
    @media (min-width: ${breakpoints.desktop}) {
        height: 45px;
    }
`

export const HeaderPhone = () => {
    return (
        <PhoneBox>
            <PhoneTag href={`tel:${contactInformation.phoneNumberHeader}`}>
                <PhoneStyled /> {contactInformation.phoneNumberHeader}
            </PhoneTag>
        </PhoneBox>
    )
}
