import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../ui-kit/globalValue'
import { useLocale } from 'i18n/useLocale'
import { Link } from 'gatsby'

import { withLocation } from 'utils/withLocation'

const LanguageTag = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-end;
    height: 35px;
    padding-left: 20px;
    padding-right: 10px;
    @media (min-width: ${breakpoints.tablet}) {
        padding-right: 0px;
    }
    @media (min-width: ${breakpoints.desktop}) {
        height: 45px;
    }
`
const Style = {
    active: `
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
        :hover {
            cursor: pointer;
          opacity:0.7 ;
        }
    `,
    passive: `
    // text-shadow: 1px 0 0  #999;
    border-bottom: 1px solid #999;
    border-top: 1px solid transparent;
    `,
}
const Button = styled.div`
    color: #fff;
    padding: 0 5px;
    background-color: transparent;
    font-size: 24px;
    transition-property: color, background-color;
    transition-duration: 0.35s;
    white-space: nowrap;
    text-decoration: none;
    ${(props) => Style[props.styles]};
    @media (min-width: ${breakpoints.tablet}) {
        font-size: 18px;
    }
    @media (min-width: ${breakpoints.desktop}) {
        font-size: 20px;
    }
`

const LanguageSwitchRaw = ({ location }) => {
    const currentLocal = useLocale()

    return (
        <LanguageTag>
            {currentLocal === 'uk' ? (
                <>
                    <Button as="p" styles="passive">
                        UA
                    </Button>
                    <Button as={Link} styles="active" to={`/en${location}`}>
                        EN
                    </Button>
                </>
            ) : (
                <>
                    <Button
                        as={Link}
                        styles="active"
                        to={`${location.slice(3)}`}
                    >
                        UA
                    </Button>
                    <Button as="p" styles="passive">
                        EN
                    </Button>
                </>
            )}
        </LanguageTag>
    )
}
export const LanguageSwitch = withLocation(LanguageSwitchRaw)
